import {
  PRODUCT_TYPE_PREFIX_BOAT,
  PRODUCT_TYPE_PREFIX_CARAVAN_TRAILER,
  PRODUCT_TYPE_PREFIX_ELECTRIC_MOBILITY,
  PRODUCT_TYPE_PREFIX_HOME,
  PRODUCT_TYPE_PREFIX_MOTOR,
  PRODUCT_TYPE_PREFIX_MOTORCYCLE,
  PRODUCT_TYPE_PREFIX_PET,
} from "raci-react-library";

export const getProductTypeDescription = (productType: string): string => {
  switch (productType.toUpperCase()) {
    case PRODUCT_TYPE_PREFIX_BOAT:
      return "Boat";
    case PRODUCT_TYPE_PREFIX_CARAVAN_TRAILER:
      return "Caravan and trailer";
    case PRODUCT_TYPE_PREFIX_ELECTRIC_MOBILITY:
      return "Electric mobility";
    case PRODUCT_TYPE_PREFIX_HOME:
      return "Home";
    case PRODUCT_TYPE_PREFIX_MOTOR:
      return "Car";
    case PRODUCT_TYPE_PREFIX_MOTORCYCLE:
      return "Motorcycle";
    case PRODUCT_TYPE_PREFIX_PET:
      return "Pet";
    default:
      return "";
  }
};

export default getProductTypeDescription;
