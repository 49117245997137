import { PRE_FORM_SESSION_STATE_KEY } from "@/shared/constants";
import {
  CancellationPageNames,
  cancellationRoutes,
  preFormRoutes,
  utilityRoutes,
} from "@/shared/routing/routes.config";
import { PreFormState } from "@/views/PreForm/types";
import { isEmptyObject, useGetSessionState } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useHasValidSession from "../useHasValidSession";

export const webChatDisallowPages = [
  ...utilityRoutes,
  ...preFormRoutes,
  ...cancellationRoutes.filter((r) => r.name === CancellationPageNames.Confirmation),
].map((route) => route.path.toLowerCase());

/**
 * TODO - Use shared WebChat Frontend/Backend code
 * - Use RRL WebChat component and Raci.Core WebChat Service in BFF
 * - Copy hook details from a Policy Self Service Spark app like CEO or MAP once BFF is updated
 */
const useGetWebChatInformation = () => {
  const location = useLocation();
  const hasValidSession = useHasValidSession();
  // TODO - Getting webChatConfig from state until BFF is updated to use Raci.Core WebChat Service
  const { policyDetailsResponse } = useGetSessionState<PreFormState>(PRE_FORM_SESSION_STATE_KEY);

  const [pointOfContactId, setPointOfContactId] = useState("");
  const [shouldRenderWebChat, setShouldRenderWebChat] = useState(false);
  const [crmId, setCrmId] = useState("");

  const currentLocation = location.pathname.toLowerCase();
  const shouldGetWebChatInformation =
    hasValidSession &&
    !webChatDisallowPages?.includes(currentLocation) &&
    !isEmptyObject(policyDetailsResponse) &&
    policyDetailsResponse?.webChatConfig?.skillPointOfContactId !== undefined;

  useEffect(() => {
    if (shouldGetWebChatInformation) {
      setPointOfContactId(policyDetailsResponse?.webChatConfig?.skillPointOfContactId ?? "");
      setShouldRenderWebChat(true);
    }
  }, [currentLocation, policyDetailsResponse, shouldGetWebChatInformation]);
  // CurrentLocation is a required dep otherwise will not check agent availability when user navigates from page to page

  return { pointOfContactId, crmId, shouldRender: shouldRenderWebChat };
};

export default useGetWebChatInformation;
