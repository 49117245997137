// <------------------>
//  Common

import { PhoneNumber } from "raci-react-library";

// <------------------>
export const AUSTRALIA_LOCALE = "en-AU";
export const NON_BREAKING_SPACE = "\u00a0";
export const LAYOUT_HEADER_HEIGHT = "50px";
export const LAYOUT_HEADER_HEIGHT_MOBILE = "80px";

// <------------------>
//  Session/Atom
// <------------------>
export const SESSION_KEY_PREFIX = "RAC_POLCAN_";
export const CONTEXT_POLICY_NUMBER_SESSION_KEY = `${SESSION_KEY_PREFIX}ContextPolicyNumber`;
export const CONTEXT_FLOW_STATE_SESSION_KEY = `${SESSION_KEY_PREFIX}FlowState`;
export const CONFIRMATION_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}Confirmation`;
export const PRE_FORM_SESSION_STATE_KEY = "PreForm";
export const ATOM_SESSION_ID = `${SESSION_KEY_PREFIX}AtomSessionId`;

// <------------------>
//  API Client
// <------------------>
export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const LOOKUP_SERVICE_API_BASE_URL = process.env.REACT_APP_LOOKUP_SERVICE_API_URL ?? "";
export const MFA_OTP_SERVICE_API_BASE_URL = process.env.REACT_APP_MFA_OTP_SERVICE_API_BASE_URL ?? "";

// <------------------>
//  ADB2C
// <------------------>
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";

// <------------------------->
//  Policy Property Key Names
// <------------------------->
export const POLICY_PROPERTY_POLICY_NUMBER = "Policy number";
export const POLICY_PROPERTY_HOME_ADDRESS = "Address";
export const POLICY_PROPERTY_VEHICLE_MODEL = "Model";
export const POLICY_PROPERTY_PET_BREED = "Breed";
export const POLICY_PROPERTY_PET_NAME = "Name";
export const POLICY_PROPERTY_BOAT_TYPE = "Type";
export const POLICY_PROPERTY_REGISTRATION_NUMBER = "Registration number";

// <------------------------->
//  Phone Numbers
// <------------------------->
/** @deprecated TODO - Replace with RRL InsurancePhoneNumber */
export const Raci13PhoneNumber: PhoneNumber = {
  href: "tel:131703",
  display: "13\u00a017\u00a003",
  displayWithoutSpaces: "131703",
};
export const RoadsidePhoneNumber: PhoneNumber = {
  href: "tel:1300138021",
  display: "1300\u00a0138\u00a0021",
  displayWithoutSpaces: "1300138021",
};
