import { HTTP_STATUS_CODE_TIMEOUT, setIntervalGuarded, useSessionTimeRemaining } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_CONFIRMATION_URL, ROUTE_UTILITY_SESSION_TIMEOUT_URL } from "../../routing/routes.config";
import useApiClient from "../useApiClient";
import { ApiException } from "../useApiClient/ClientProxy.generated";
import useHasValidSession from "../useHasValidSession";
import useOnExpiry from "../useOnExpiry";

export const EXPIRY_CHECK_INTERVAL_IN_MILLISECONDS = 30000; // 30 Seconds

export const useSessionExpiry = () => {
  const client = useApiClient();
  const location = useLocation();
  const navigate = useNavigate();
  const onExpiry = useOnExpiry();
  const hasValidSession = useHasValidSession();
  const { timeoutTime, setTimeoutTime } = useSessionTimeRemaining();
  const [wait, setWait] = useState(EXPIRY_CHECK_INTERVAL_IN_MILLISECONDS);

  /**
   * Skip expiry check if:
   * - Session ID is not valid
   * - Current location is SessionTimeout or Confirmation pages
   */
  const shouldSkipExpiryCheck =
    !hasValidSession ||
    location.pathname.endsWith(ROUTE_UTILITY_SESSION_TIMEOUT_URL) ||
    location.pathname.endsWith(ROUTE_CONFIRMATION_URL);

  /**
   * Check session if:
   * - Session ID is valid
   * - Current location is SessionTimeout or Confirmation pages
   */
  const checkSessionExpiry = async () => {
    const getNextDelay = (expiry: number) => Math.max(expiry * 500, EXPIRY_CHECK_INTERVAL_IN_MILLISECONDS);

    if (!shouldSkipExpiryCheck) {
      try {
        const response = await client.getsessionexpiry();

        const expiryInSeconds = parseInt(response.result);
        setWait(getNextDelay(expiryInSeconds));
        setTimeoutTime(expiryInSeconds > 0 ? new Date().getTime() + expiryInSeconds * 1000 : null);
      } catch (ex) {
        const exception = ex as ApiException;
        if (exception?.status === HTTP_STATUS_CODE_TIMEOUT) {
          setWait(getNextDelay(-1));
          onExpiry();
        } else {
          navigate(ROUTE_UTILITY_SESSION_TIMEOUT_URL, { replace: true });
        }
      }
    }
  };

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled && !shouldSkipExpiryCheck) {
      const handle = setIntervalGuarded(async () => {
        if (!isCancelled) {
          await checkSessionExpiry();
        }
      }, wait);
      return () => {
        isCancelled = true;
        clearTimeout(handle);
      };
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutTime, shouldSkipExpiryCheck]);

  return { checkSessionExpiry };
};

export default useSessionExpiry;
