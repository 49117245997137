import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useMemo } from "react";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const commonPageProps = usePage();
  const { steps, activeStepIndex, canNavigateToPreviousStep, navigateToPreviousStep } = useRoutes();

  const sidebarTitle = process.env.REACT_APP_NAME ?? "";

  const sidebarContent = useMemo(
    () => (
      <SidebarContent
        pdsUrl={process.env.REACT_APP_PDS_PED_URL || EMPTY_URL}
        feedbackUrl={process.env.REACT_APP_FEEDBACK_URL || EMPTY_URL}
      />
    ),
    [],
  );

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: () => navigateToPreviousStep && navigateToPreviousStep(),
  };

  return {
    sidebarTitle,
    sidebarContent,
    steps,
    activeStepIndex,
    mobileStepperProps,
    ...commonPageProps,
  };
};

export default useStepperPage;
