import { useGetPreviousPageSessionState } from "raci-react-library";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cancellationRoutes } from "../routes.config";
import { RouteInformation } from "../types";

export interface StepInformation {
  name: string;
  path: string;
}

export interface UseRoutesResults {
  isPolicyCancellationsFlow: boolean;
  steps: StepInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount: number;
  navigateToPreviousStep?: () => void;
  canNavigateToPreviousStep: boolean;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();
  const { path: previousPageUrl } = useGetPreviousPageSessionState();

  const isPolicyCancellationsFlow = cancellationRoutes.findIndex((item) => item.path === location.pathname) > -1;

  const steps: StepInformation[] = isPolicyCancellationsFlow
    ? cancellationRoutes.map(({ name, path }) => ({ name, path }))
    : [];

  const totalStepCount = isPolicyCancellationsFlow ? steps.length : 0;

  const activeStepIndex = isPolicyCancellationsFlow
    ? steps.findIndex((item) => item.path === location.pathname)
    : undefined;

  const navigateToPreviousStep = useMemo(() => {
    return isPolicyCancellationsFlow && previousPageUrl && (activeStepIndex ?? 0) > 0
      ? () => navigate(previousPageUrl, { replace: true })
      : undefined;
  }, [navigate, isPolicyCancellationsFlow, activeStepIndex, previousPageUrl]);

  const canNavigateToPreviousStep =
    !!navigateToPreviousStep &&
    steps.indexOf(cancellationRoutes[cancellationRoutes.length - 1]) !== activeStepIndex &&
    activeStepIndex !== 0;

  return {
    isPolicyCancellationsFlow,
    steps,
    formRoutes: cancellationRoutes,
    activeStepIndex,
    totalStepCount,
    navigateToPreviousStep,
    canNavigateToPreviousStep,
  };
};

export default useRoutes;
