import useFlowState from "@/shared/hooks/useFlowState";
import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ROUTE_CONFIRMATION_URL,
  ROUTE_UTILITY_CALL_US_URL,
  ROUTE_UTILITY_NOT_FOUND_URL,
  ROUTE_UTILITY_ROADBLOCK_URL,
  ROUTE_UTILITY_SESSION_TIMEOUT_URL,
  ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL,
} from "../routes.config";

export interface RouteGuardProps {
  disableChecks?: string[];
}

/**
 * Utility Error pages that redirect to default error page (SystemUnavailable)
 * when FlowState.HasError is true and current location is not in the array.
 */
const UTILITY_ERROR_PAGE_URLS = [
  ROUTE_UTILITY_CALL_US_URL.toLowerCase(),
  ROUTE_UTILITY_NOT_FOUND_URL.toLowerCase(),
  ROUTE_UTILITY_ROADBLOCK_URL.toLowerCase(),
  ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL.toLowerCase(),
];

export const RouteGuard = ({ disableChecks = [] }: RouteGuardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [flowState] = useFlowState();
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();

  const currentLocation = location.pathname.toLowerCase();
  const hasTimedOut = flowState.hasTimedOut;
  const hasError = flowState.hasError;
  const isCompleted = flowState.isCompleted;

  useEffect(() => {
    if (hasTimedOut) {
      if (currentLocation !== ROUTE_UTILITY_SESSION_TIMEOUT_URL.toLowerCase()) {
        // Redirect user back to confirmation if user tries to navigate away
        navigate(ROUTE_UTILITY_SESSION_TIMEOUT_URL, {
          state: {
            referrer: location.pathname,
          },
        });
      }
    } else if (isCompleted) {
      if (currentLocation !== ROUTE_CONFIRMATION_URL.toLowerCase()) {
        // Redirect user back to confirmation if user tries to navigate away
        navigate(ROUTE_CONFIRMATION_URL);
      }
    } else if (hasError) {
      if (!UTILITY_ERROR_PAGE_URLS.includes(currentLocation)) {
        // Redirect user system unavailable page if user tries to navigate away from error page
        navigate(ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL, {
          state: {
            referrer: location.pathname,
          },
        });
      }
    } else {
      const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;
      if (!bypass && previousPageUrl && !isPreviousPageCompleted) {
        // Navigate user to previous page if allowed
        // Replace browser history as user should not be able to navigate back to where they came from
        navigate(previousPageUrl, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableChecks, previousPageUrl, isPreviousPageCompleted, hasTimedOut, hasError, isCompleted, currentLocation]);

  return <></>;
};

export default RouteGuard;
