import { lazy } from "react";
import { RouteInformation } from "./types";

const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const NotFound = lazy(() => import("../../views/NotFound"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));
const Roadblock = lazy(() => import("../../views/Roadblock"));
const CallUs = lazy(() => import("../../views/CallUs"));

const PreForm = lazy(() => import("../../views/PreForm"));
const CancelPolicy = lazy(() => import("../../views/CancelPolicy"));
const Confirmation = lazy(() => import("../../views/Confirmation"));

export enum PreFormRoute {
  PreForm = "pre-form",
}

export enum CancellationRoutes {
  CancelPolicy = "Review",
  Confirmation = "Confirmation",
}

export enum UtilityRoutes {
  NotFound = "404",
  SessionTimeout = "408",
  SystemUnavailable = "500",
  Roadblock = "Roadblock",
  CallUs = "CallUs",
}

export enum PreFormPageNames {
  PreForm = "",
}

export enum CancellationPageNames {
  CancelPolicy = "Cancellation details",
  Confirmation = "Confirmation",
}

export enum UtilityPageNames {
  CallUs = "Call us",
  NotFound = "Not found",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
  Roadblock = "Roadblock",
}

export enum UtilityPageHeadings {
  CallUs = "Uh oh!",
  NotFound = "Uh oh!",
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
  Roadblock = "Uh oh!",
  Default = "Uh oh!",
}

export const ROUTE_PRE_FORM_URL = `${process.env.REACT_APP_HOMEPAGE}`;
export const ROUTE_CANCEL_POLICY_URL = `${process.env.REACT_APP_HOMEPAGE}/${CancellationRoutes.CancelPolicy}`;
export const ROUTE_CONFIRMATION_URL = `${process.env.REACT_APP_HOMEPAGE}/${CancellationRoutes.Confirmation}`;
export const ROUTE_UTILITY_SESSION_TIMEOUT_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.SessionTimeout}`;
export const ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.SystemUnavailable}`;
export const ROUTE_UTILITY_ROADBLOCK_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.Roadblock}`;
export const ROUTE_UTILITY_NOT_FOUND_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.NotFound}`;
export const ROUTE_UTILITY_CALL_US_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.CallUs}`;

const preFormRoutesInfo: Record<PreFormRoute, RouteInformation> = {
  [PreFormRoute.PreForm]: {
    key: PreFormRoute.PreForm,
    path: ROUTE_PRE_FORM_URL,
    name: PreFormPageNames.PreForm,
    element: <PreForm />,
  },
};

export const preFormRoutes: RouteInformation[] = [preFormRoutesInfo[PreFormRoute.PreForm]];

export const cancellationRoutes: RouteInformation[] = [
  {
    key: CancellationRoutes.CancelPolicy,
    path: ROUTE_CANCEL_POLICY_URL,
    name: CancellationPageNames.CancelPolicy,
    element: <CancelPolicy />,
  },
  {
    key: CancellationRoutes.Confirmation,
    path: ROUTE_CONFIRMATION_URL,
    name: CancellationPageNames.Confirmation,
    element: <Confirmation />,
  },
];

export const cancellationRoutesInfo: Record<CancellationRoutes, RouteInformation> = {
  [CancellationRoutes.CancelPolicy]: {
    key: CancellationRoutes.CancelPolicy,
    path: ROUTE_CANCEL_POLICY_URL,
    name: CancellationPageNames.CancelPolicy,
    element: <CancelPolicy />,
  },
  [CancellationRoutes.Confirmation]: {
    key: CancellationRoutes.Confirmation,
    path: ROUTE_CONFIRMATION_URL,
    name: CancellationPageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.NotFound,
    path: ROUTE_UTILITY_NOT_FOUND_URL,
    name: UtilityPageNames.NotFound,
    element: <NotFound />,
    heading: UtilityPageHeadings.NotFound,
  },
  {
    key: UtilityRoutes.SessionTimeout,
    path: ROUTE_UTILITY_SESSION_TIMEOUT_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
    heading: UtilityPageHeadings.SessionTimeout,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.SystemUnavailable,
  },
  {
    key: UtilityRoutes.Roadblock,
    path: ROUTE_UTILITY_ROADBLOCK_URL,
    name: UtilityPageNames.Roadblock,
    element: <Roadblock />,
    heading: UtilityPageHeadings.Roadblock,
  },
  {
    key: UtilityRoutes.CallUs,
    path: ROUTE_UTILITY_CALL_US_URL,
    name: UtilityPageNames.CallUs,
    element: <CallUs />,
    heading: UtilityPageHeadings.CallUs,
  },
];

export const allRoutes = [...cancellationRoutes, ...utilityRoutes];

/**
 * Disable RouteGuard checks for all utility pages and for all confirmation pages.
 * Once confirmation page has been reached, user should not be able to navigate back from confirmation.
 */
export const disableRouteGuardChecks = utilityRoutes
  .map((item) => item.path)
  .concat(ROUTE_PRE_FORM_URL.toLowerCase())
  .concat(ROUTE_CONFIRMATION_URL.toLowerCase());
